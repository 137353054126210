import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Events from '@sportson/core-web/libs/Events';
import { ScrollEvents } from '@sportson/core-web/libs/Events/constants';
import { injectModel } from '@sportson/core-web/state';
import scrollFunctions from '@sportson/core-web/utils/scrollFunctions';

class ScrollRestoration extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        page: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.prevScroll = 0;
        this.history = [];

        this.events = [];
    }

    componentDidMount() {
        const handleWindowResize = debounce(this.handleWindowResize, 500);
        window.addEventListener('resize', handleWindowResize);
        this.setScrollRestoration('manual');

        this.events[ScrollEvents.TO_TOP] = Events.subscribe(ScrollEvents.TO_TOP, () =>
            scrollFunctions.scrollTo({ smooth: false, left: 0, top: 0 }),
        );
    }

    shouldComponentUpdate(nextProps) {
        const body = document && document.body ? document.body : false;

        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.addScrollHistoryObj(this.props.location.pathname);
        }

        if (nextProps.page.type === 'product_category' && body) {
            body.style.overflowAnchor = 'none';
        } else {
            body.style.overflowAnchor = '';
        }

        return nextProps.page.isFetching !== this.props.page.isFetching;
    }

    componentDidUpdate() {
        const scrollObj = this.getScrollHistoryObj(this.props.location.pathname);
        const scrollY = scrollObj && scrollObj.y && scrollObj.y;
        const scrollHeight = scrollObj && scrollObj.height;
        const thisPage = this.props.page;

        document.body.style.minHeight = scrollHeight ? `${scrollHeight}px` : '0px';

        if (!thisPage.isFetching) {
            let top = scrollY || 0;
            if (thisPage.type === 'storm_checkout') {
                top = 0;
            }

            // TODO: Se över denna ful-lösning vid tillfälle,
            // https://grebban.slack.com/archives/C19H0E5A9/p1726662129372539 för mer info kring scrollbugg
            setTimeout(() => {
                scrollFunctions.scrollTo({
                    smooth: false,
                    left: 0,
                    top,
                    scrollRestoreIsTriggered: true,
                });
            }, 0);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        this.setScrollRestoration('auto');
        Events.unsubscribe(this.events[ScrollEvents.TO_TOP]);
    }

    addScrollHistoryObj = (path) => {
        const y = window.pageYOffset || document.documentElement.scrollTop;
        const height = document.body.scrollHeight;

        path && this.history.unshift({ path, height, y });
        if (this.history.length > 5) {
            this.history.length = 5;
        }
    };

    getScrollHistoryObj = (path) => {
        const matches = this.history.filter((obj) => obj.path === path);
        return matches[0] ? matches[0] : false;
    };

    setScrollRestoration = (value) => {
        if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = value;
        }
    };

    handleWindowResize = () => {
        const scrollObj = this.history[this.props.location.path] || 0;
        document.body.style.minHeight = this.prevScroll !== scrollObj.y && '0';
        this.prevScroll = scrollObj.y;
    };

    render() {
        return null;
    }
}

export default withRouter(injectModel('page')(ScrollRestoration));
