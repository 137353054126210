const isBrowser = typeof window !== 'undefined';
const isIOS = isBrowser && window.CSS && CSS.supports('-webkit-overflow-scrolling: touch');
/**
 * Scroll to a specified position on the page
 *
 * @version 1.1
 * @param {Object} options - Object with options
 * @param {boolean} options.smooth - Specifies whether the scrolling should animate smoothly, or happen instantly in a single jump.
 * @param {number} options.left - Specifies the number of pixels along the X axis to scroll the window.
 * @param {number} options.top - Specifies the number of pixels along the Y axis to scroll the window.
 */

interface scrollToOptions {
    smooth: boolean;
    left: number;
    top: number;
    scrollRestoreIsTriggered: boolean;
}

let globalScrollRestoreIsTriggered = false;

export const scrollTo = ({
    smooth = true,
    left = 0,
    top = 0,
    scrollRestoreIsTriggered = false,
}: scrollToOptions): void => {
    if (isBrowser) {
        if (document.documentElement && 'scrollBehavior' in document.documentElement.style) {
            globalScrollRestoreIsTriggered = scrollRestoreIsTriggered;
            if (isScrollAllowed()) {
                window.scrollTo({
                    behavior: smooth !== false ? 'smooth' : 'auto',
                    left,
                    top,
                });
                globalScrollRestoreIsTriggered = false;
            } else {
                setTimeout(() => {
                    window.scrollTo({
                        behavior: smooth !== false ? 'smooth' : 'auto',
                        left,
                        top,
                    });
                    globalScrollRestoreIsTriggered = false;
                }, 200);
            }
        } else {
            // Calculates distance to travel based on where we are on the page
            let start = 0;
            const time = 500;
            const currentPos = window.pageYOffset;

            const step = (currentTime: number) => {
                start = !start ? currentTime : start;
                const progress = currentTime - start;

                // Less than top, we scroll down, else up
                if (currentPos < top) {
                    window.scrollTo(0, ((top - currentPos) * progress) / time + currentPos);
                } else {
                    window.scrollTo(0, currentPos - ((currentPos - top) * progress) / time);
                }
                // Recursively run function until we reach our destination
                if (progress < time) {
                    window.requestAnimationFrame(step);
                } else {
                    window.scrollTo(0, top);
                }
            };

            window.requestAnimationFrame(step);
        }
    }
};

/**
 * Scroll lib to prevent scroll on all browsers.
 * Replacement for preventScroll in GC's application model.
 *
 * @author Timmie Sarjanen & Kristoffer Forsgren
 * @version 1.1.1
 */

const DATA_ATTR = 'data-prevent-scroll';
const SCROLLBAR_ATTR = 'data-scrollbar-gap';

export const isScrollAllowed = (): boolean => document.body.getAttribute(DATA_ATTR) === null;

export const preventScroll = (): void => {
    if (isBrowser && isScrollAllowed()) {
        const { body } = document;
        const scrollTop = window.pageYOffset;
        const scrollbarGap = window.innerWidth - document.documentElement.clientWidth;
        body.setAttribute(DATA_ATTR, 'true');

        if (isIOS) {
            if (scrollTop) {
                body.style.setProperty('top', `-${scrollTop}px`);
            }

            /**
             * Timeout allows for the overlay to open first
             *
             * Setting a timeout with no delay parameter
             * executes immediately the next event cycle.
             */
            setTimeout(() => {
                body.style.position = 'fixed';
            });

            body.style.position = 'fixed';
            body.style.width = '100%';
        }
        body.style.overflow = 'hidden';

        if (scrollbarGap > 0) {
            body.setAttribute(SCROLLBAR_ATTR, `${scrollbarGap}px`);
            body.style.paddingRight = `${scrollbarGap}px`;
        }
    }
};

export const allowScroll = (): void => {
    if (isBrowser && !isScrollAllowed()) {
        const { body } = document;
        const scrollTop = Math.abs(parseInt(body.style.top || '0', 10));

        if (isIOS) {
            if (scrollTop) {
                body.style.removeProperty('top');
                window.scrollTo(0, scrollTop);
            }

            /**
             * Timeout allows for the overlay to close before scrolling back
             *
             * Setting a timeout with no delay parameter
             * executes immediately the next event cycle.
             */
            setTimeout(() => {
                if (!globalScrollRestoreIsTriggered) {
                    window.scrollTo(0, scrollTop);
                }
                globalScrollRestoreIsTriggered = false;
            });

            body.style.removeProperty('position');
            body.style.removeProperty('width');
        }

        body.style.removeProperty('overflow');
        body.style.removeProperty('padding-right');

        body.removeAttribute(SCROLLBAR_ATTR);
        body.removeAttribute(DATA_ATTR);
    }
};

export default {
    scrollTo,
    allowScroll,
    isScrollAllowed,
    preventScroll,
};
